import React from "react";
import "../Team/Team.scss";
import { useInView } from "react-intersection-observer";

const Person = ({ data }) => {
  const imgSrc =
    data.image.src && require(`../../../../assets/images/team/${data.image.src}`);
  const screenWidth = window.innerWidth;
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const HandlePerson = () => {
    if (screenWidth < 768) {
      return inView ? "active" : "";
    }
    return "showText";
  };

  return (
    <>
      <div ref={ref} inView={inView} className={`cell ${HandlePerson()}`}>
        <img src={imgSrc} alt="" />
        <h3>{data.name} /</h3>
        <p>{data.jobPosition}</p>
      </div>
    </>
  );
};

export default Person;
