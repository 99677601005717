import React from "react";
import "./Team.scss";
import { useTranslation } from "react-i18next";
import Person from "../Person/Person";

const Team = () => {
  const { t } = useTranslation("team");
  const data = t("team");
  const values = t("values.description");

  return (
    <section className="Team">
      <div className="container-whoweare">
        <h2>{t("whoWeAre.title")}</h2>
        <p>{t("whoWeAre.description")}</p>
      </div>
      <div className="container-about">
        <div>
          <h2>{t("mission.title")}</h2>
          <p>{t("mission.description")}</p>
        </div>
        <div>
          <h2>{t("values.title")}</h2>
          {values?.map((person) => {
            return (
              <>
                <ul>
                  <li>{person}</li>
                </ul>
              </>
            );
          })}
        </div>
        <div>
          <h2>{t("vision.title")}</h2>
          <p>{t("vision.description")}</p>
        </div>
      </div>
      <div>
        <h2 className="title-team">{t("title")}</h2>
      </div>
      <main className="people">
        {data?.map((person) => {
          return <Person data={person} />;
        })}
      </main>
    </section>
  );
};

export default Team;
