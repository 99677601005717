import React from "react";
import "./SupportPage.scss";
import { useTranslation } from "react-i18next";
import background from "../../../../assets/images/bgsupport.png"
import SubscriptionForm from "../FormManager/FormManager";

const SupportPage = () => {
  const { t } = useTranslation("donation");

  return (
    <section className="SupportPage">
      <img src={background} alt="" />
      <div>
        <h3 className="top-navigation">{t("title")}</h3>
      </div>
      <article>
        <h3>{t("heading")}</h3>
        <p>{t("paragraph")}</p>
        <h2>{t("highlight")}</h2>
      </article>
      <SubscriptionForm />
      <hr/>
    </section>
  );
};

export default SupportPage;
