import React from "react";
import "./Home.scss";
import background from "../../../../assets/images/Home.png";
import EvidenceTableArea from "../EvidenceTableArea/EvidenceTableArea";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation("home");

  return (
    <section className="Home">
      <header>
        <img src={background} alt="imagem de fundo" />
        <h1>SOMAS</h1>
      </header>
      <div>
        <h3>{t("heading")}</h3>
        <h5>{t("subHeading.0")}</h5>
        <h5>{t("subHeading.1")}</h5>
      </div>
      <EvidenceTableArea />
      <section>
        <p>{t("lastSection")}</p>
      </section>
    </section>
  );
};

export default Home;
