import React, { useEffect, useState } from "react";
import "./App.scss";
import Footer from "./global/Footer/Footer";
import Home from "./main/home/Home/Home";
import Menu from "./global/menu/Menu/Menu";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Timeline from "./main/timeline/Timeline/Timeline";
import i18n from "../i18n";
import i18next from "i18next";
import Researches from "./main/researches/Researches/Researches";
import ResearchMain from "./main/researches/ResearchMain/ResearchMain";
import Team from "./main/team/Team/Team";
import SupportPage from "./main/support/SupportPage/SupportPage";
import HolderPortal from "./main/HolderPortal/HolderPortal";
import Cookies from "./global/Cookies/Cookies";
import {getItemFromLocalStorage} from "../localStorage";

function App() {
  const [language, setLanguage] = useState(window.localStorage.i18nextLng);

  useEffect(() => {
    setLanguage(i18next.language);
  }, []);

  const selectLanguage = language => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <>
      {getItemFromLocalStorage("cookie") ? "" : <Cookies/>}
      <Menu />
      <div className="LanguageSelector">
        <p
          className={language === "pt" ? "active" : null}
          onClick={() => selectLanguage("pt")}
        >
          PT
        </p>
        <p
          className={language === "en" ? "active" : null}
          onClick={() => selectLanguage("en")}
        >
          EN
        </p>
      </div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/nosso-percurso" component={Timeline} />
          <Route path="/pesquisas" component={Researches} />
          <Route path="/pesquisa/:id/:page" component={ResearchMain} />
          <Route path="/time" component={Team} />
          <Route path="/quero-apoiar" component={SupportPage} />
          <Route path="/portaldotitular" component={HolderPortal} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
