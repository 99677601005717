import React from "react";
import "./Checkbox.scss";
import PropTypes from "prop-types";

const Checkbox = ({ label, value, updateState }) => {
  const handleCheckbox = event => {
    updateState(event.target.checked);
  };

  return (
    <label className="Checkbox">
      <input value={value} onChange={handleCheckbox} type="checkbox" />
      <span className="checkmark" />
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  updateState: PropTypes.func,
  value: PropTypes.bool
};

export default Checkbox;
