import React from "react";
import "./Menu.scss";
import { useState } from "react";
import Icons from "../Icons/Icons";
import Donation from "../Donation/Donation";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { t } = useTranslation("menu");

  const toggleMenu = () => setShowSidebar(!showSidebar);

  return (
    <nav className="SideMenu">
      <input id="burger-menu" type="checkbox" onClick={toggleMenu} />
      <label htmlFor="burger-menu">
        <div className={`menu${showSidebar ? " active" : ""}`}>
          <span className="burger" />
        </div>
      </label>
      <div className={`menu-items${showSidebar ? " active" : ""}`}>
        <div className="menu-icons">
          <Icons />
          <div className="items">
            <a href="/" rel="noopener noreferrer">
              {t("home")}
            </a>
            <a href="/nosso-percurso" rel="noopener noreferrer">
              {t("ourHistory")}
            </a>
            <a href="/time" rel="noopener noreferrer">
              {t("ourTeam")}
            </a>
            <a href="/pesquisas" rel="noopener noreferrer">
              {t("researches")}
            </a>
            <a href="/quero-apoiar" rel="noopener noreferrer">
              {t("support")}
            </a>
            <a
              href="http://blog.somas.org.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog <span> {t("blog")}</span>
            </a>
          </div>
        </div>
        <Donation />
      </div>
    </nav>
  );
};

export default Menu;
