import React from "react";
import "./Icons.scss";

import { ReactComponent as Home } from "../../../../assets/images/icons/home.svg";
import { ReactComponent as Historia } from "../../../../assets/images/icons/historia.svg";
import { ReactComponent as Time } from "../../../../assets/images/icons/time.svg";
import { ReactComponent as Pesquisa } from "../../../../assets/images/icons/pesquisa.svg";
import { ReactComponent as Apoio } from "../../../../assets/images/icons/apoio.svg";
import { ReactComponent as Blog } from "../../../../assets/images/icons/blog.svg";
import { ReactComponent as Curadoria } from "../../../../assets/images/icons/curadoria.svg";

const Icons = () => {
  return(
    <div className="Icons">
      <a href="/" rel="noopener noreferrer"><Home /></a>
      <a href="/nosso-percurso" rel="noopener noreferrer"><Historia /></a>
      <a href="/time" rel="noopener noreferrer"><Time /></a>
      <a href="/pesquisas" rel="noopener noreferrer"><Pesquisa /></a>
      <a href="/quero-apoiar" rel="noopener noreferrer"><Apoio /></a>
      <a href="http://blog.somas.org.br/" target="_blank" rel="noopener noreferrer"><Blog /></a>
      <a href="https://cultura.mastertech.com.br/" target="_blank" rel="noopener noreferrer"><Curadoria /></a>
    </div>
  )
}

export default Icons;
