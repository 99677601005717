import React, { useState } from "react";

const Year = ({ time, buttons }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const text = time.text;
  const hide = buttons.hide;
  const show = buttons.show;

  return (
    <>
      <article>
        <div className="container">
          <h3>{time.year}</h3>
          <div>
            <p className={isExpanded ? "expanded" : ""}>{text}</p>
            <button onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? hide : show}</button>
          </div>
        </div>
      </article>
    </>
  )
}

export default Year;
