import React, { useState } from "react";
import "./FormManager.scss";
import { useTranslation } from "react-i18next";
import FormField from "../FormField/FormField";

const FormManager = () => {
  const { t } = useTranslation("donation");
  const [activeForm, setActiveForm] = useState("donate");

  return (
    <section className="FormManager">
      <div className="tabs">
        <p className={activeForm === "donate" ? "active" : null}
          onClick={() => setActiveForm("donate")}
        >
          {t("forms.tabs.0")}
        </p>
        <p className={activeForm === "donate-p" ? "active" : null}
          onClick={() => setActiveForm("donate-p")}
        >
          {t("forms.tabs.1")}
        </p>
        <p className={activeForm === "volunteer" ? "active" : null}
          onClick={() => setActiveForm("volunteer")}
        >
          {t("forms.tabs.2")}
        </p>
        <p className={activeForm === "interested" ? "active" : null}
          onClick={() => setActiveForm("interested")}
        >
          {t("forms.tabs.3")}
        </p>
        
      </div>
      <div className="messageForm">
        <h4>{t(`forms.category.${activeForm}.message`)}</h4>
      </div>
      <FormField activeForm={activeForm}/>
    </section>
  );
};

export default FormManager;
