import React, { useEffect, useState } from "react";
import "./Researches.scss";
import { ReactComponent as PlusIcon } from "../../../../assets/images/icons/add.svg";
import Carousel from "../Carousel/Carousel";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../form/Checkbox/Checkbox";
import { Link } from "react-router-dom";

const Researches = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [funding, setFunding] = useState(false);
  const [filters, setFilters] = useState("");
  const { t } = useTranslation("research");

  const data = t("researches");
  const backgroundImage = () => {
    const bgImg = require(`../../../../assets/images/${data[selectedIndex].description.image.src}`);
    return {
      backgroundImage: `url(${bgImg})`
    };
  };

  const handleClick = reference => {
    reference === "inProgress"
      ? setInProgress(!inProgress)
      : setInProgress(false);
    reference === "finished" ? setFinished(!finished) : setFinished(false);
    reference === "funding" ? setFunding(!funding) : setFunding(false);
  };

  useEffect(() => {
    const filters = {
      inProgress,
      finished,
      funding
    };
    setFilters(filters);
  }, [inProgress, finished, funding]);

  return (
    <section className="Researches" style={backgroundImage()}>
      <div className="container">
        <h2>{t("title")}</h2>
        <article className="status">
          <div className="status-desktop">
            <Checkbox
              updateState={setInProgress}
              label={t("inputs.inProgress")}
              value={inProgress}
            />
            <Checkbox
              label={t("inputs.finished")}
              value={finished}
              updateState={setFinished}
            />
            <Checkbox
              label={t("inputs.funding")}
              value={funding}
              updateState={setFunding}
            />
          </div>
          <div className="status-mobile">
            <p
              className={inProgress ? "selected" : null}
              onClick={() => handleClick("inProgress")}
            >
              {t("inputs.inProgress")}
            </p>
            <p
              className={finished ? "selected" : null}
              onClick={() => handleClick("finished")}
            >
              {t("inputs.finished")}
            </p>
            <p
              className={funding ? "selected" : null}
              onClick={() => handleClick("funding")}
            >
              {t("inputs.funding")}
            </p>
          </div>
        </article>
        <article className="description-container">
          <div className="title-description">
            <p className="project-status">
              {t(`researches.${selectedIndex}.status`)}
            </p>
            <h3>{t(`researches.${selectedIndex}.researchName`)}</h3>
            <p>{t(`researches.${selectedIndex}.description.text`)}</p>
            <Link
              to={{
                pathname:
                  data[selectedIndex].site === ""
                    ? `/pesquisa/${selectedIndex}/problema`
                    : data[selectedIndex].site
              }}
              target={data[selectedIndex].site !== "" && "_blank"}
            >
              <button>{t("buttons.knowMore")}</button>
              <span>
                <PlusIcon />
              </span>
            </Link>
          </div>
          <Carousel
            researchesData={data}
            setSelected={setSelectedIndex}
            selected={selectedIndex}
            filters={filters}
          />
        </article>
      </div>
    </section>
  );
};

export default Researches;
